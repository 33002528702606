import type { ComponentData } from '~/types/GenericTable'
import type { TooltipStyleSettings } from '~/types/StyleSettings'

export const useCustomComponents = () => {
  function useComponent(component: Component, props: Record<string, unknown>, visible?: boolean, access?: boolean, tooltip?: TooltipStyleSettings): ComponentData<Component> {
    return {
      value: component,
      props: props,
      tooltip: tooltip,
      visible: visible != undefined ? visible : true,
      access: access != undefined ? access : true,
    }
  }
  return {
    useComponent,
  }
}
